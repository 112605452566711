* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background: #e89fc3;
  border: 1px solid rgba(10, 13, 28, 0.35);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #e89fc3;
}

*::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
  border-radius: 10px;
}
body {
  font-family: "Montserrat", sans-serif;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
  height: 300px;
}

@media screen and (max-width: 768px) {
  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}
